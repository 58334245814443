
<template>
    <img :src="$config.apihost+'/'+src" :style="style" />
</template>

<script>
export default {
    name: "page-img",
    props: {
        /* 初始值 */
        src: { type: String, default: "" },
        style: { type: String, default: "" },
        class: { type: String, default: "" },
    },
};
</script>